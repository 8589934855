import axios from 'axios';

const annualLicenseStartYear = 2020;

const state = {
    annualLicenseStartYear: annualLicenseStartYear,
    uploadProgress: 10
};

const getters = {
};

const actions = {
    async getUserApplications(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getApplicationsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;
    },
    async getPaginatedApplicationItems(state, url) {// get paginated applications
        const authorizationToken = state.rootState.url.authorizationToken;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {}, { headers: header});// make api call
        return response.data;
    },
    async getApplication(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getApplicationUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;
    },
    async getNewApplications(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getNewApplicationsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;
    },
    async getApplicationType(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getApplicationTypeUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;
    },
    async getApplicationTypeId(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getApplicationTypeIdUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;
    },
    async getAdditionalQulifications(state) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getAdditionalQulificationsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {}, { headers: header});// make api call
        return response.data;
    },
    async addNewAdditionalQualification(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.addAdditionalQulificationUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;
    },
    async getUserPendingAq(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getUserPendingAqUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;
    },
    async saveApplicationTransaction(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.saveApplicationTransactionUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;
    },
    async saveUserCpdPoints(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.saveUserCpdPointsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;
    },
    async getUserCpdPoints(state) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getUserCpdPointsUrl;
        let id = state.rootState.user.userId;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {'id' : id}, { headers: header});// make api call
        return response.data;
    },
    async deleteUserCpdRecord(state, data) {// save user Jurisdiction
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.deleteUserCpdRecordUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;
    },
    async getAnnualLicenseDetails(state, year = null) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getAnnualLicenseDetailsUrl;
        let id = state.rootState.user.userId;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {'id' : id, year : year}, { headers: header});// make api call
        return response.data;
    },
    async createUserApplication(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.createUserApplicationUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;
    },
    async updateUserTransaction(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updateTransactionUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;
    },
    async getUserApplicationDetails(state, data) {// save user Jurisdiction
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getUserApplicationDetailsUrl;
        const id = state.rootState.user.userId;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {'id' : id, 'code' : data}, { headers: header});// make api call
        // console.log(response)
        return response.data;
    },
    async uploadChangeNameDocuments(state, data) {// upload user documents
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.uploadChangeNameDocumentsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {data}, {
            headers: header,
            onUploadProgress: uploadEvent => {
                state.rootState.user.uploadProgress = Math.round(uploadEvent.loaded
                        / uploadEvent.total * 100);
            }
        });// make api call
        return response.data;
    },
    async getChangeNameApplication(state) {// save user Jurisdiction
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getChangeNameApplicationUrl;
        const id = state.rootState.user.userId;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {'id' : id}, { headers: header});// make api call
        return response.data;
    },
    async saveUserChangeName(state, data) {// save user change of name
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.saveUserChangeNameUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;
    },
    async addNewLogs(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.addNewLogsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;
    },
    async getUserPendingLog(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getUserPendingLogUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;
    },
    async updateTransNo(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updateTransNoUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;
    },
    async getOtherPayment(state) {// save user Jurisdiction
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getOtherPaymentUrl;
        const id = state.rootState.user.userId;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {'id' : id}, { headers: header});// make api call
        return response.data;
    },
    async reSubmitApplication(state, id) {// save user Jurisdiction
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.reSubmitApplicationUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {'id' : id}, { headers: header});// make api call
        return response.data;
    },
    async getLifeLicenseApplication(state) {// save user Jurisdiction
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getLifeLicenseApplicationUrl;
        const id = state.rootState.user.userId;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {'id' : id}, { headers: header});// make api call
        return response.data;
    },
    async updateLifeLicense(state, data) {// upload user documents
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updateLifeLicenseUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {data}, {
            headers: header,
            onUploadProgress: uploadEvent => {
                state.rootState.user.uploadProgress =
                        Math.round(uploadEvent.loaded / uploadEvent.total * 100);
            }
        });// make api call
        return response.data;
    },
    async getRevalidationApplication(state) {// save user Jurisdiction
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getRevalidationApplicationUrl;
        const id = state.rootState.user.userId;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {'id' : id}, { headers: header});// make api call
        return response.data;
    },
    async uploadRevalidationDocuments(state, data) {// upload user documents
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.uploadRevalidationDocumentsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {data}, {
            headers: header,
            onUploadProgress: uploadEvent => {
                state.rootState.user.uploadProgress = Math.round(uploadEvent.loaded
                        / uploadEvent.total * 100);
            }
        });// make api call
        return response.data;
    },
    async saveUserEics(state, data) {// save user change of name
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.saveUserEicsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;
    },
    async getEicsApplication(state) {// save user Jurisdiction
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getEicsApplicationUrl;
        const id = state.rootState.user.userId;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {'id' : id}, { headers: header});// make api call
        return response.data;
    },
    async getCtcFullRegApplication(state) {// save user Jurisdiction
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getCtcFullRegApplicationUrl;
        const id = state.rootState.user.userId;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {'id' : id}, { headers: header});// make api call
        return response.data;
    },
    async uploadCtcFullRegDocuments(state, data) {// upload user documents
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.uploadCtcFullRegDocumentsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {data}, {
            headers: header,
            onUploadProgress: uploadEvent => {
                state.rootState.user.uploadProgress = Math.round(uploadEvent.loaded
                        / uploadEvent.total * 100);
            }
        });// make api call
        return response.data;
    },
    async saveNewAQData(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.saveNewAQDataUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {data}, { headers: header});// make api call
        return response.data;
    },
    async getUserPreviousApplications(state, data) {// save user Jurisdiction
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getUserPreviousApplicationsUrl;
        const id = state.rootState.user.userId;
        const code = data;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {'id' : id, 'code' : code}, { headers: header});// make api call
        return response.data;
    },
    async getTempVerifyDetails(state) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getTempVerifyDetailsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {}, { headers: header});// make api call
        return response.data;
    },
    async getTemporaryRegDetails(state, code) {// save user Jurisdiction
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getTemporaryRegDetailsUrl;
        const id = state.rootState.user.userId;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {'id' : id, code : code}, { headers: header});// make api call
        return response.data;
    },
    async internshipStatus(state) {// get internship status
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.internshipStatusUrl;
        const id = state.rootState.user.userDataId;// add user data id
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, { id : id }, { headers: header});// make api call
        return response.data;
    },
    async getDiasporaLicense(state, data = {}) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getDiasporaLicenseUrl;
        data.id = state.rootState.user.userId;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
    async createDiasporaLicense(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.createDiasporaLicenseUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, {
            headers: header,
            onUploadProgress: uploadEvent => {
                state.state.uploadProgress = Math.round(uploadEvent.loaded / uploadEvent.total * 100);
            }
        });// make api call
        return response.data;
    },
    async getPaginatedExactApplications(state, url) {// get pending signups
        const authorizationToken = state.rootState.url.authorizationToken;
        // const url = state.rootState.url.getExactApplicationsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url,{},{ headers: header });// make api call
        return response.data;
    },
    async reSubmitDiasoraApplication(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.reSubmitDiasoraApplicationUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    }
};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations
}