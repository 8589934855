<template>
  <footer class="footer px-4 bg-white">
    <div class="row align-items-center justify-content-lg-between">
      <div class="col-lg-6">
        <div class="copyright text-center text-lg-left text-muted">
          © {{year}} Medical and Dental Council of Nigeria
        </div>
      </div>
      <div class="col-lg-6">
        <ul class="nav nav-footer justify-content-center justify-content-lg-end">
          <div class="copyright text-center text-lg-left text-muted">
            Designed By
            <a href="javascript:void(0)" target="_blank">
              BCF
            </a>
          </div>
        </ul>
      </div>
    </div>
  </footer>
</template>
<script>
    import { mapActions } from 'vuex';

    export default {
      data() {
        return {
          year: new Date().getFullYear()
        };
      },
      methods: {
        ...mapActions(['getUpdateProfileInfo', 'fetchUserProfile', 'getUserPendingUploads']),
        checkStatus() {// check for user account status
          this.fetchUserProfile().then((res) => {
              let tempDoc = res.basic_profile && res.basic_profile.has_sub_registration_type && // check for temporary Doc
                            res.basic_profile.has_sub_registration_type &&
                            res.basic_profile.has_sub_registration_type.registration_type &&
                            res.basic_profile.has_sub_registration_type.registration_type.code == "temporary-reg";
              this.isLoadingBlade = false;
              if(res && (res.new_account != 'yes') && ! res.diaspora_status) { return this.$router.push({ name: 'Update Profile' }); }// update diaspora status first
              if(res && (res.new_account != 'yes') && (res.diaspora_status == 'yes') && ! res.full_reg_status) { // update fullreg status  only if diaspora status is yes
                return this.$router.push({ name: 'Update Profile' }); 
              }
              if(res.new_account == 'yes' && tempDoc) {// redirect to new temp account 
                if((this.$route.name != 'New Account Purchase Insurance')
                    || (this.$route.name != 'Temporary License Renewal Payment')) {
                  this.$router.push({ name: 'New Temporary Account' });
                }                
              } else if(res.new_account == 'yes') {// redirect to new account form
                  if((this.$route.name != 'New Account Purchase Insurance')) {
                    this.$router.push({ name: 'New Account' });
                  }
              } else {// check for profile incompleteness
                this.hasEmptyData(); 
              }
          });
        },
        hasEmptyData() {// get profile incompleteness
            this.getUpdateProfileInfo().then((res) => {
                if(! res.status) {
                  this.$router.push({ name: 'Update Profile' });
                }
            });
          },
      },
      mounted() {
        this.checkStatus();
        this.getUserPendingUploads();
      }
    };
</script>
<style scoped>
  .bg-white {
      background-color: #e63c4705 !important;
  }
</style>
