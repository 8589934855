const rootUrl = 'https://test.api.portal.mdcn.gov.ng';
const remitaAssessmentCallBackUrl = 'https://test.portal.mdcn.gov.ng/assessment-callback';
const remitaAssessmentReRegisterCallUrl = 'https://test.portal.mdcn.gov.ng/assessment-register-callback';
const remitaTempVerifyCallUrl = 'https://test.portal.mdcn.gov.ng/temp-verify-callback';
const remitaDocStatusVerifyCallUrl = 'https://test.portal.mdcn.gov.ng/doctor-status-verify-callback';
const remitaRemediationCallBackUrl = 'https://test.portal.mdcn.gov.ng/remediation/callback';
const remitaAdditionalAssessmentCallBackUrl = 'https://test.portal.mdcn.gov.ng/additional/assessment/callback';
// user module Urls
const profileUserUrl = rootUrl+'/get-user-profile';
const locationsUrl = rootUrl+'/get-locations';
const updateUserWorkUrl = rootUrl+'/update-user-work-profile';
const getUserDocumentsUrl = rootUrl+'/get-user-documents';
const updateUserProfileUrl = rootUrl+'/update-user-profile';
const specializationsUrl = rootUrl+'/get-specializations';
const uploadUserDocumentsUrl = rootUrl+'/upload-user-document';
const uploadUserPhotoUrl = rootUrl+'/upload-user-photo';
const saveJurisdictionsUrl = rootUrl+'/save-user-jurisdictions';
const removeJurisdictionUrl = rootUrl+'/delete-user-jurisdiction';
const saveRefereesUrl = rootUrl+'/save-user-referees';
const removeRefereeUrl = rootUrl+'/delete-user-referee';
const updateAdditionalRecordsUrl = rootUrl+'/update-additional-records';
const getAdditionalRecordsUrl = rootUrl+'/get-user-additional-records';
const getUpdateProfileInfoUrl = rootUrl+'/get-user-profile-info';
const getRegistrationTypesUrl = rootUrl+'/get-registration-types';
const getSubRegistrationTypesUrl = rootUrl+'/get-sub-registration-types';
const getDashboardInfoUrl = rootUrl+'/get-dashboard-info';
const changePasswordUrl = rootUrl+'/change-user-password';
const getUserPhotoUrl = rootUrl+'/get-user-photo';
const getUserCertificatesUrl = rootUrl+'/get-user-certificates';
const disableProfileUrl = rootUrl+'/disable-user-profile';
const validatingFolioUrl = rootUrl+'/validating-folio-number';
const getDoctorStatusProfileUrl = rootUrl+'/get-doctor-status-profile';
const getUserNotificationsUrl = rootUrl+'/get-user-notifications';
const deleteUserNotificationUrl = rootUrl+'/delete-user-notification';
const verifyRecaptchaUrl = rootUrl+'/verify-recaptcha';
const getHospitalsUrl = rootUrl+'/get-hospitals';
const getUserPendingUploadsUrl = rootUrl+'/get-user-pending-uploads';
const updateUserForeignAssociationUrl = rootUrl+'/update/user/association';
// updateProfile module Urls
const updateBioDataUrl = rootUrl+'/update-user-bio-data';
const updateSpecializationUrl = rootUrl+'/update-user-specialization';
// Internship module Urls
const getInternshipUrl = rootUrl+'/get-user-internship';
const uploadInternshipDocumentsUrl = rootUrl+'/upload-user-internship-document';
const updateInternshipUrl = rootUrl+'/update-user-internship';
// eduRecord module Urls
const getEducationalRecordsUrl = rootUrl+'/get-user-educational-records';
const getInstitutionsUrl = rootUrl+'/get-institutions';
const saveEduRecordsUrl = rootUrl+'/save-user-educational-records';
const deleteUserEduRecordUrl = rootUrl+'/delete-user-educational-record';
// Appointment module Urls
const getAppointmentsUrl = rootUrl+'/get-user-appointments';
const deleteUserAppointmentUrl = rootUrl+'/delete-user-appointment';
const saveUserAppointmentUrl = rootUrl+'/save-user-appointment';
const getAppointmentPostingsUrl = rootUrl+'/get-appointment-posting';
const getPostingDepartmentsUrl = rootUrl+'/get-posting-departments';
const savePostingUrl = rootUrl+'/save-appointment-posting';
const deletePostingUrl = rootUrl+'/delete-appointment-posting';
// addQualification module Urls
const getUserAddQualUrl = rootUrl+'/get-user-additional-qualifications';
const addOldAdditionalQualificationUrl = rootUrl+'/add-old-additional-qualification';
const deleteUserAqUrl = rootUrl+'/delete-additional-qualification';
// Payment module Urls
const getRemitaPaymentStatusUrl = rootUrl+'/get-rrr-status';
const getRemitaOrderPaymentStatusUrl = rootUrl+'/get-remita-order-status';
const updateApplicationTransactionUrl = rootUrl+'/update-application-transaction';
const getUserPaymentsUrl = rootUrl+'/get-user-payments';
const generatePaymentRRRUrl = rootUrl+'/generate-remita-rrr';
const saveOtherPaymentsUrl = rootUrl+'/save-other-payments';
const getRemitaPaymentDetailsUrl = rootUrl+'/remita-order-status-only';
const getDocStatusVerifyDetailsUrl = rootUrl+'/doctor-status-verify-details';
const updateMwanTransactionUrl = rootUrl+'/update/mwan/transaction';
const verifyMwanPaymentUrl = rootUrl+'/verify/mwan/payment';
const updateDiasporaAppTransactionUrl = rootUrl+'/update/diaspora/payment';
const getFlutterwaveTransactionUrl = rootUrl+'/get/flutterwave/status';
// Parameter module Urls
const getSiteParameterUrl = rootUrl+'/get-parameter-value';
// newAccount module Urls
const getBioDataUrl = rootUrl+'/get-user-bio-data';
const getUserSpecializationUrl = rootUrl+'/get-user-specialization';
const getUserRegistrationDetailsUrl = rootUrl+'/get-user-registration-payment';
const createNewUserApplicationUrl = rootUrl+'/create-new-user-application';
const saveNewApplicationTransactionUrl = rootUrl+'/save-new-application-transaction';
const submitApplicationUrl = rootUrl+'/submit-user-application';
const updateInternshipCountryUrl = rootUrl+'/update-application-country';
const createTempDocSignupUrl = rootUrl+'/temp-register';
const updateTempAssessmentUrl = rootUrl+'/update-temporary-assessment';
// Insurance module Urls
const getIndemnityUrl = rootUrl+'/get-user-insurance';
const getValidIndemnityUrl = rootUrl+'/get-user-valid-insurance';
const getAnIndemnityUrl = rootUrl+'/get-an-insurance';
const addIndemnityUrl = rootUrl+'/add-user-insurance';
const reQueryInsuranceUrl = rootUrl+'/re-query-insurance';
// https://cornerstone.com.ng/partner/dykesmdcn
const cornerstonePaymentUrl = 'https://cornerstone.com.ng/partner/dykesmdcn';
const paymentCallbackUrl = rootUrl+'/insurance/callback';
// Application module Urls
const getApplicationsUrl = rootUrl+'/get-user-applications';
const getApplicationUrl = rootUrl+'/get-user-application';
const getNewApplicationsUrl = rootUrl+'/get-user-new-applications';
const getApplicationTypeUrl = rootUrl+'/get-application-type';
const getApplicationTypeIdUrl = rootUrl+'/get-application-type-by-id';
const getAdditionalQulificationsUrl = rootUrl+'/get-additional-qualifications';
const addAdditionalQulificationUrl = rootUrl+'/add-new-additional-qualification';
const getUserPendingAqUrl = rootUrl+'/get-user-pending-aq';
const saveApplicationTransactionUrl = rootUrl+'/save-application-transaction';
const saveUserCpdPointsUrl = rootUrl+'/save-cpd-points';
const getUserCpdPointsUrl = rootUrl+'/get-cpd-points';
const deleteUserCpdRecordUrl = rootUrl+'/delete-cpd-point';
const getAnnualLicenseDetailsUrl = rootUrl+'/get-annual-license-info';
const createUserApplicationUrl = rootUrl+'/create-user-application';
const updateTransactionUrl = rootUrl+'/update-transaction';
const getUserApplicationDetailsUrl = rootUrl+'/get-user-application-payment';
const uploadChangeNameDocumentsUrl = rootUrl+'/upload-change-name-document';
const getChangeNameApplicationUrl = rootUrl+'/get-change-name-application';
const saveUserChangeNameUrl = rootUrl+'/save-change-name-application';
const addNewLogsUrl = rootUrl+'/save-logs-application';
const getUserPendingLogUrl = rootUrl+'/get-user-pending-log';
const updateTransNoUrl = rootUrl+'/update-application-trans-no';
const getOtherPaymentUrl = rootUrl+'/get-other-payment';
const reSubmitApplicationUrl = rootUrl+'/re-submit-application';
const updateLifeLicenseUrl = rootUrl+'/update-life-license';
const getLifeLicenseApplicationUrl = rootUrl+'/get-life-license-application';
const getRevalidationApplicationUrl = rootUrl+'/get-revalidation-application';
const uploadRevalidationDocumentsUrl = rootUrl+'/upload-revalidation-document';
const saveUserEicsUrl = rootUrl+'/save-eics-application';
const getEicsApplicationUrl = rootUrl+'/get-ecis-application';
const getCtcFullRegApplicationUrl = rootUrl+'/get-ctc-full-application';
const uploadCtcFullRegDocumentsUrl = rootUrl+'/upload-ctc-full-document';
const saveNewAQDataUrl = rootUrl+'/save-new-aq-data';
const getUserPreviousApplicationsUrl = rootUrl+'/get-user-previous-apps';
const getTempVerifyDetailsUrl = rootUrl+'/get-temp-verify-details';
const getTemporaryRegDetailsUrl = rootUrl+'/get-temp-renewal-payment';
const internshipStatusUrl = rootUrl+'/get-internship-status';
const getDiasporaLicenseUrl = rootUrl+'/get-diaspora-license';
const createDiasporaLicenseUrl = rootUrl+'/create-diaspora-license';
const reSubmitDiasoraApplicationUrl = rootUrl+'/submit/diaspora/application';
// sanction urls
const getSanctionsUrl = rootUrl+'/get-all-sanctions';
const getSearchedSanctionsUrl = rootUrl+'/get-searched-sanctions';
const getExitRegisterUrl = rootUrl+'/get-exit-register';
const sendComplaintUrl = rootUrl+'/send-complaints';
const sendQuackReportUrl = rootUrl+'/send-quack-report';
const sendExitRegisterFormUrl = rootUrl+'/send-exit-register';
// assessment Urls
const getAssessmentTypesUrl = rootUrl+'/get-assessment-types';
const getAssessmentBatchUrl = rootUrl+'/get-assessment-batch';
const getAssessmentPaymentUrl = rootUrl+'/get-assessment-payment';
const generateAssessmentRrrUrl = rootUrl+'/generate-assessment-rrr';
const getAssRemitaPaymentDetailsUrl = rootUrl+'/get-assessment-payment-order';
const getAssessmentRrrStatusUrl = rootUrl+'/get-assessment-rrr-status';
const getApplicantInfoUrl = rootUrl+'/get-assessment-applicant-info';
const saveAssessmentApplicantDataUrl = rootUrl+'/save-assessment-applicant-data';
const uploadAssessmentPhotoUrl = rootUrl+'/upload-assessment-photo';
const deleteAssessmentEduRecordUrl = rootUrl+'/delete-assessment-edu-data';
const deleteAssessmentQualificationUrl = rootUrl+'/delete-assessment-qual-data';
const deleteAssessmentEmploymentUrl = rootUrl+'/delete-assessment-employ-data';
const deleteAssessmentExaminationUrl = rootUrl+'/delete-assessment-examination';
const deleteAssessmentInstitutionUrl = rootUrl+'/delete-assessment-institution';
const deleteAssessmentRefereeUrl = rootUrl+'/delete-assessment-referee';
const submitAssessmentFormUrl = rootUrl+'/submit-assessment-form';
const addExtraAssessmentChargeUrl = rootUrl+'/add-assessment-extra-charge';
const getAssessmentInstitutionsUrl = rootUrl+'/get-assessment-institutions';
const uploadAssessmentDocumentsUrl = rootUrl+'/upload-assessment-file';
const addAdditionalAssessmentRrrUrl = rootUrl+'/add-additional-assessment-rrr';
// Remediation Programme Urls
const getRemediationBatchUrl = rootUrl+'/get/remediation/batch';
const getRemediationPaymentUrl = rootUrl+'/get/remediation/payment';
const getRemediationRrrStatusUrl = rootUrl+'/get/remediation/rrr/status';
const getRemediationRemitaPaymentDetailsUrl = rootUrl+'/get/remediation/payment/order';
const getPendingRemediationApplicationUrl = rootUrl+'/pending/remediation/application';
const updateRemediationStudentDataUrl = rootUrl+'/save/remediation/personal/data';
const uploadRemediationStudentPhotoUrl = rootUrl+'/upload/remediation/photo';
const uploadRemediationStudentDocumentsUrl = rootUrl+'/upload/remediation/documents';
const submitRemediationApplicationUrl = rootUrl+'/submit/remediation/form';
// Remita Urls & Keys
const remitaPaymentUrl ='https://login.remita.net/remita/ecomm/init.reg';
const remitaPaymentCallbackUrl = rootUrl+'/remita-callback';
const remitaMerchantId = '632028472';
const remitaApiKey = '361049';
// Authorization Key
const authorizationToken = '2TcXrFhPN7qIRCEobdeZcYkqZgiSRRmYOwvjce1';
// other Urls
const loadingBladeUrl = rootUrl+'/storage/images/loading.gif';
const printCertificateUrl = rootUrl+'/print-certificate';
const blankImageUrl = rootUrl+'/storage/images/blank-photo.png';
const completeImageUrl = rootUrl+'/storage/images/complete.jpeg';
const certificateImageUrl = rootUrl+'/storage/images/certificate.png';
const logoutUserUrl = rootUrl+'/logout';
const loginUserUrl = rootUrl+'/login';
const registerUserUrl = rootUrl+'/register';
const resetPasswordUrl = rootUrl+'/password/email';
const updatePasswordUrl = rootUrl+'/password/reset';
const websiteUrl = 'https://mdcn.gov.ng';
const embassyDocUrl = rootUrl+'/resources/embassy.pdf';
const printReceiptUrl = rootUrl+'/print-rrr-receipt';
const printTicketUrl = rootUrl+'/print-induction-ticket';
// other Details
const perPage = 20;
const recaptchaKey = '6LfIxSUaAAAAAGt9xeD2zcDuZWwcK9lv2Pe6Yu4P';
// MWAN Paystack Details
const mwanPaystackKey = 'pk_test_d72e3dfffb3acee9b8ab3124cf5122b347b5b1f6';
const mwanSplitCode = 'SPL_NLCCwyUyQj';
// const recaptchaKey = '6LfIxSUaAAAAAGt9xeD2zcDuZWwcK9lv2Pe6Yu4P';
// const recaptchaSecretKey= '6LfIxSUaAAAAAKbTl8TavrmiI2zulLx82EQ41kOS';

// const remitaApiKey = 'U0hFTEx8MTUwOTM3MTg1NDczOXwyNjdjNDBiZmI5ZjMzMjg5M2I3MWI2YzEzZWUxYTQ5YjUxOTRhMjY5ZDljOWUzNmI0MWUxOTgyYzI1NDUyYTMxM2NlM2QxYTdmZjQxMTExN2M5MTU1NjgxNWYyYmEwMTI3ZWY3MmU4M2MxNmE2ZjBmNjE3Y2Q2OTNlYzA1ODA4Nw=='
// const annualLicenseStartYear = 2020;

const state = {
    reSubmitDiasoraApplicationUrl: reSubmitDiasoraApplicationUrl,
    getFlutterwaveTransactionUrl: getFlutterwaveTransactionUrl,
    updateDiasporaAppTransactionUrl: updateDiasporaAppTransactionUrl,
    updateUserForeignAssociationUrl: updateUserForeignAssociationUrl,
    createDiasporaLicenseUrl: createDiasporaLicenseUrl,
    getDiasporaLicenseUrl: getDiasporaLicenseUrl,
    addAdditionalAssessmentRrrUrl: addAdditionalAssessmentRrrUrl,
    verifyMwanPaymentUrl: verifyMwanPaymentUrl,
    updateMwanTransactionUrl: updateMwanTransactionUrl,
    submitRemediationApplicationUrl: submitRemediationApplicationUrl,
    uploadRemediationStudentDocumentsUrl: uploadRemediationStudentDocumentsUrl,
    uploadRemediationStudentPhotoUrl: uploadRemediationStudentPhotoUrl,
    updateRemediationStudentDataUrl: updateRemediationStudentDataUrl,
    getPendingRemediationApplicationUrl: getPendingRemediationApplicationUrl,
    getRemediationRemitaPaymentDetailsUrl: getRemediationRemitaPaymentDetailsUrl,
    getRemediationRrrStatusUrl: getRemediationRrrStatusUrl,
    getRemediationPaymentUrl: getRemediationPaymentUrl,
    getRemediationBatchUrl: getRemediationBatchUrl,
    getUserPendingUploadsUrl: getUserPendingUploadsUrl,
    getHospitalsUrl: getHospitalsUrl,
    internshipStatusUrl: internshipStatusUrl,
    verifyRecaptchaUrl: verifyRecaptchaUrl,
    updateTempAssessmentUrl: updateTempAssessmentUrl,
    getTemporaryRegDetailsUrl: getTemporaryRegDetailsUrl,
    uploadAssessmentDocumentsUrl: uploadAssessmentDocumentsUrl,
    getAssessmentInstitutionsUrl: getAssessmentInstitutionsUrl,
    deleteUserNotificationUrl: deleteUserNotificationUrl,
    getUserNotificationsUrl: getUserNotificationsUrl,
    getDoctorStatusProfileUrl: getDoctorStatusProfileUrl,
    getDocStatusVerifyDetailsUrl: getDocStatusVerifyDetailsUrl,
    createTempDocSignupUrl: createTempDocSignupUrl,
    getRemitaPaymentDetailsUrl: getRemitaPaymentDetailsUrl,
    getTempVerifyDetailsUrl: getTempVerifyDetailsUrl,
    getUserPreviousApplicationsUrl: getUserPreviousApplicationsUrl,
    saveNewAQDataUrl: saveNewAQDataUrl,
    addExtraAssessmentChargeUrl: addExtraAssessmentChargeUrl,
    submitAssessmentFormUrl: submitAssessmentFormUrl,
    deleteAssessmentRefereeUrl: deleteAssessmentRefereeUrl,
    deleteAssessmentInstitutionUrl: deleteAssessmentInstitutionUrl,
    deleteAssessmentExaminationUrl: deleteAssessmentExaminationUrl,
    deleteAssessmentEmploymentUrl: deleteAssessmentEmploymentUrl,
    deleteAssessmentQualificationUrl: deleteAssessmentQualificationUrl,
    deleteAssessmentEduRecordUrl: deleteAssessmentEduRecordUrl,
    uploadAssessmentPhotoUrl: uploadAssessmentPhotoUrl,
    saveAssessmentApplicantDataUrl: saveAssessmentApplicantDataUrl,
    getApplicantInfoUrl: getApplicantInfoUrl,
    getAssessmentRrrStatusUrl: getAssessmentRrrStatusUrl,
    getAssRemitaPaymentDetailsUrl: getAssRemitaPaymentDetailsUrl,
    generateAssessmentRrrUrl: generateAssessmentRrrUrl,
    getAssessmentPaymentUrl: getAssessmentPaymentUrl,
    getAssessmentBatchUrl: getAssessmentBatchUrl,
    getAssessmentTypesUrl: getAssessmentTypesUrl,
    perPage: perPage,
    userId: localStorage.getItem('user_id') || null,
    authorizationToken: authorizationToken,
    remitaApiKey: remitaApiKey,
    remitaPaymentUrl: remitaPaymentUrl,
    remitaMerchantId: remitaMerchantId,
    remitaPaymentCallbackUrl: remitaPaymentCallbackUrl,
    rootUrl: rootUrl,
    profileUserUrl: profileUserUrl,
    locationsUrl: locationsUrl,
    updateUserWorkUrl: updateUserWorkUrl,
    getUserDocumentsUrl: getUserDocumentsUrl,
    updateUserProfileUrl: updateUserProfileUrl,
    specializationsUrl: specializationsUrl,
    uploadUserDocumentsUrl: uploadUserDocumentsUrl,
    uploadUserPhotoUrl: uploadUserPhotoUrl,
    saveJurisdictionsUrl: saveJurisdictionsUrl,
    removeJurisdictionUrl: removeJurisdictionUrl,
    saveRefereesUrl: saveRefereesUrl,
    removeRefereeUrl: removeRefereeUrl,
    updateAdditionalRecordsUrl: updateAdditionalRecordsUrl,
    getAdditionalRecordsUrl: getAdditionalRecordsUrl,
    getUpdateProfileInfoUrl: getUpdateProfileInfoUrl,
    getRegistrationTypesUrl: getRegistrationTypesUrl,
    getSubRegistrationTypesUrl: getSubRegistrationTypesUrl,
    getDashboardInfoUrl: getDashboardInfoUrl,
    changePasswordUrl: changePasswordUrl,
    getUserPhotoUrl: getUserPhotoUrl,
    getUserCertificatesUrl: getUserCertificatesUrl,
    printCertificateUrl: printCertificateUrl,
    loadingBladeUrl: loadingBladeUrl,
    blankImageUrl: blankImageUrl,
    completeImageUrl: completeImageUrl,
    certificateImageUrl: certificateImageUrl,
    logoutUserUrl: logoutUserUrl,
    loginUserUrl: loginUserUrl,
    registerUserUrl: registerUserUrl,
    resetPasswordUrl: resetPasswordUrl,
    updatePasswordUrl: updatePasswordUrl,
    updateBioDataUrl: updateBioDataUrl,
    updateSpecializationUrl: updateSpecializationUrl,
    getInternshipUrl: getInternshipUrl,
    uploadInternshipDocumentsUrl: uploadInternshipDocumentsUrl,
    updateInternshipUrl: updateInternshipUrl,
    getEducationalRecordsUrl: getEducationalRecordsUrl,
    getInstitutionsUrl: getInstitutionsUrl,
    saveEduRecordsUrl: saveEduRecordsUrl,
    deleteUserEduRecordUrl: deleteUserEduRecordUrl,
    getAppointmentsUrl: getAppointmentsUrl,
    deleteUserAppointmentUrl: deleteUserAppointmentUrl,
    saveUserAppointmentUrl: saveUserAppointmentUrl,
    getAppointmentPostingsUrl: getAppointmentPostingsUrl,
    getPostingDepartmentsUrl: getPostingDepartmentsUrl,
    savePostingUrl: savePostingUrl,
    deletePostingUrl: deletePostingUrl,
    getUserAddQualUrl: getUserAddQualUrl,
    addOldAdditionalQualificationUrl: addOldAdditionalQualificationUrl,
    deleteUserAqUrl: deleteUserAqUrl,
    getRemitaPaymentStatusUrl: getRemitaPaymentStatusUrl,
    getRemitaOrderPaymentStatusUrl: getRemitaOrderPaymentStatusUrl,
    updateApplicationTransactionUrl: updateApplicationTransactionUrl,
    getUserPaymentsUrl: getUserPaymentsUrl,
    getSiteParameterUrl: getSiteParameterUrl,
    getBioDataUrl: getBioDataUrl,
    getUserSpecializationUrl: getUserSpecializationUrl,
    getUserRegistrationDetailsUrl: getUserRegistrationDetailsUrl,
    createNewUserApplicationUrl: createNewUserApplicationUrl,
    saveNewApplicationTransactionUrl: saveNewApplicationTransactionUrl,
    submitApplicationUrl: submitApplicationUrl,
    getIndemnityUrl: getIndemnityUrl,
    getAnIndemnityUrl: getAnIndemnityUrl,
    addIndemnityUrl: addIndemnityUrl,
    reQueryInsuranceUrl: reQueryInsuranceUrl,
    getApplicationsUrl: getApplicationsUrl,
    getApplicationUrl: getApplicationUrl,
    getNewApplicationsUrl: getNewApplicationsUrl,
    getApplicationTypeUrl: getApplicationTypeUrl,
    getApplicationTypeIdUrl: getApplicationTypeIdUrl,
    getAdditionalQulificationsUrl: getAdditionalQulificationsUrl,
    addAdditionalQulificationUrl: addAdditionalQulificationUrl,
    getUserPendingAqUrl: getUserPendingAqUrl,
    saveApplicationTransactionUrl: saveApplicationTransactionUrl,
    saveUserCpdPointsUrl: saveUserCpdPointsUrl,
    getUserCpdPointsUrl: getUserCpdPointsUrl,
    deleteUserCpdRecordUrl: deleteUserCpdRecordUrl,
    getAnnualLicenseDetailsUrl: getAnnualLicenseDetailsUrl,
    createUserApplicationUrl: createUserApplicationUrl,
    updateTransactionUrl: updateTransactionUrl,
    getUserApplicationDetailsUrl: getUserApplicationDetailsUrl,
    uploadChangeNameDocumentsUrl: uploadChangeNameDocumentsUrl,
    getChangeNameApplicationUrl: getChangeNameApplicationUrl,
    saveUserChangeNameUrl: saveUserChangeNameUrl,
    addNewLogsUrl: addNewLogsUrl,
    getUserPendingLogUrl: getUserPendingLogUrl,
    updateTransNoUrl: updateTransNoUrl,
    getOtherPaymentUrl: getOtherPaymentUrl,
    getValidIndemnityUrl: getValidIndemnityUrl,
    reSubmitApplicationUrl: reSubmitApplicationUrl,
    disableProfileUrl: disableProfileUrl,
    updateLifeLicenseUrl: updateLifeLicenseUrl,
    getLifeLicenseApplicationUrl: getLifeLicenseApplicationUrl,
    getRevalidationApplicationUrl: getRevalidationApplicationUrl,
    uploadRevalidationDocumentsUrl: uploadRevalidationDocumentsUrl,
    getSanctionsUrl: getSanctionsUrl,
    getSearchedSanctionsUrl: getSearchedSanctionsUrl,
    getExitRegisterUrl: getExitRegisterUrl,
    generatePaymentRRRUrl: generatePaymentRRRUrl,
    saveOtherPaymentsUrl: saveOtherPaymentsUrl,
    saveUserEicsUrl: saveUserEicsUrl,
    getEicsApplicationUrl: getEicsApplicationUrl,
    getCtcFullRegApplicationUrl: getCtcFullRegApplicationUrl,
    uploadCtcFullRegDocumentsUrl: uploadCtcFullRegDocumentsUrl,
    updateInternshipCountryUrl: updateInternshipCountryUrl,
    validatingFolioUrl: validatingFolioUrl,
    sendComplaintUrl: sendComplaintUrl,
    sendQuackReportUrl: sendQuackReportUrl,
    sendExitRegisterFormUrl: sendExitRegisterFormUrl
};

const getters = {
    userPhotoUrl: state => state.rootUrl+'/storage/user'+state.userId+'/photo/',
    cpdPointUrl: state => state.rootUrl+'/storage/user'+state.userId+'/documents/cpd/',
    printCertificateUrl: state => state.printCertificateUrl,
    getAuthorizationToken: state => state.authorizationToken,
    loadingBladeUrl: state => state.loadingBladeUrl,
    blankImageUrl: state => state.blankImageUrl,
    completeImageUrl: state => state.completeImageUrl,
    certificateImageUrl: state => state.certificateImageUrl,
    getLogoutUserUrl: state => state.logoutUserUrl,
    getLoginUserUrl: state => state.loginUserUrl,
    getRegisterUserUrl: state => state.registerUserUrl,
    getResetPasswordUrl: state => state.resetPasswordUrl,
    getupdatePasswordUrl: state => state.updatePasswordUrl,
    remitaPaymentUrl: state => state.remitaPaymentUrl,
    remitaMerchantId: state => state.remitaMerchantId,
    remitaPaymentCallbackUrl: state => state.remitaPaymentCallbackUrl,
    remitaApiKey: state => state.remitaApiKey,
    getInsuranceUrl: () => cornerstonePaymentUrl,
    getCallbackUrl: () => paymentCallbackUrl,
    websiteUrl: () => websiteUrl,
    getRootUrl: () => rootUrl,
    embassyDocUrl: () => embassyDocUrl,
    printReceiptUrl: () => printReceiptUrl,
    recaptchaKey: () => recaptchaKey,
    remitaAssessmentCallBackUrl: () => remitaAssessmentCallBackUrl,
    remitaAssessmentReRegisterCallUrl: () => remitaAssessmentReRegisterCallUrl,
    remitaTempVerifyCallUrl: () => remitaTempVerifyCallUrl,
    remitaDocStatusVerifyCallUrl: () => remitaDocStatusVerifyCallUrl,
    printTicketUrl: () => printTicketUrl,
    remitaRemediationCallBackUrl: () => remitaRemediationCallBackUrl,
    mwanPaystackKey: () => mwanPaystackKey,
    mwanSplitCode: () => mwanSplitCode,
    remitaAdditionalAssessmentCallBackUrl: () => remitaAdditionalAssessmentCallBackUrl
};

const actions = {

};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations
}