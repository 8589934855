import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'
import ArgonDashboard from './plugins/argon-dashboard'
import store from './store'; 
import Flutterwave from  'flutterwave-vue-v3'

Vue.config.productionTip = false

Vue.use(ArgonDashboard)
Vue.use(Flutterwave, { publicKey: 'FLWPUBK_TEST-9983dcfe73426f6e3ce489d4f2fe0356-X' } )

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
